import {
    python,
    backend,
    mobile,
    web,
    css,
    git,
    html,
    javascript,
    reactjs,
    scrum,
    angular,
    sqlServer,
    cSharp, 
    calculadora,
    snake,
    mybox,
    ecommerce,
    jessica,
    lucas,
    mateus
} from "../assets";

export const navLinks = [
    {
        id: "about",
        title: "Sobre",
    },
    {
        id: "work",
        title: "Trabalho",
    },
    {
        id: "contact",
        title: "Contato",
    },
];

const services = [
    {
        title: "Desenvolvedor Web",
        icon: web,
    },
    {
        title: "Desenvolvedor de Apps",
        icon: mobile,
    },
    {
        title: "Desenvolvedor Backend",
        icon: backend,
    },
];

const technologies = [
    {
        name: "HTML 5",
        icon: html,
    },
    {
        name: "CSS 3",
        icon: css,
    },
    {
        name: "JavaScript",
        icon: javascript,
    },
    {
        name: "React | React Native",
        icon: reactjs,
    },
    {
        name: "Angular",
        icon: angular,
    },
    {
        name: "Python",
        icon: python,
    },
    {
      name: ".NET",
      icon: cSharp,
    },
    {
      name: "SQL Server",
      icon: sqlServer,
    },
    {
      name: "Scrum",
      icon: scrum,
    },
    {
        name: "git",
        icon: git,
    },
];

const experiences = [
    {
        title: "Desenvolvedor Full Stack",
        company_name: "MyBox Marcenaria",
        icon: mybox,
        iconBg: "#E6DEDD",
        date: "Jun 2022 - Atualmente",
        points: [
            "Desenvolvimento e manutenção de aplicações e sistemas utilizando .NET 4.8, .NET 7+, Blazor, Angular, SignalR, Webhooks e tecnologias correlatas.",
            "Desenvolvimento e manutenção de aplicativos móveis com React Native, incluindo publicação e gerenciamento na Apple e Google Play.",
            "Manutenção de sistemas legados da empresa para otimizar usabilidade e performance, tendo C# e .NET como arquitetura.",
            "Colaborar com equipes multifuncionais, incluindo designers e outros desenvolvedores, para criar aplicações de alta qualidade.",
            "Implementar design responsivo e garantir compatibilidade entre navegadores."
        ],
    },
];

const testimonials = [
    {
        testimonial:
            "O Henrique tem sido um parceiro incrível em todo o processo de desenvolvimento do nosso site. Ele trouxe ao projeto uma combinação de habilidade técnica e criatividade, que resultou em um site funcional e atrativo que superou todas as minhas expectativas.",
        name: "Jessica Gama",
        designation: "CFO",
        company: "MB",
        image: jessica,
    },
    {
        testimonial:
            "Você desenvolveu um app para a empresa e entregou um produto final excepcional. Conseguiu entender nossas necessidades e superou nossas expectativas em relação ao prazo de entrega e qualidade do aplicativo.",
        name: "Mateus Pereira",
        designation: "Gerente de TI",
        company: "Âncora IT",
        image: mateus,
    },
    {
        testimonial:
            "Trabalhar com o Henrique foi uma experiência incrível. Sua habilidade para resolver problemas e propor soluções inovadoras é impressionante.",
        name: "Gabriel Bontempo",
        designation: "Desenvolvedor",
        company: "",
        image: lucas,
    },
];

const projects = [
    {
        name: "E-commerce",
        description:
            "Esse E-commerce possui um sistema de gerenciamento de conteúdo customizado que permite aos administradores atualizar produtos e gerenciar pedidos, além de incluir recursos como autenticação de usuário e carrinho de compras.",
        tags: [
            {
                name: "python",
                color: "blue-text-gradient",
            },
            {
                name: "django",
                color: "blue-text-gradient",
            },
            {
                name: "JS",
                color: "pink-text-gradient",
            },
            {
                name: "HTML & CSS",
                color: "green-text-gradient",
            },
        ],
        image: ecommerce,
        source_code_link: "https://github.com/HenriquedSS1912/ecommerce",
    },
    {
        name: "Calculadora",
        description:
            "O objetivo do projeto era fornecer uma ferramenta útil e conveniente para realizar cálculos matemáticos básicos e avançados de forma rápida e eficiente em seus dispositivos móveis, alem de testar o meu conhecimento em React Native.",
        tags: [
            {
                name: "reactnative",
                color: "blue-text-gradient",
            },
            {
                name: "JS",
                color: "green-text-gradient",
            },
        ],
        image: calculadora,
        source_code_link: "https://github.com/HenriquedSS1912/calculadora",
    },
    {
        name: "Snake Game",
        description:
            "Este projeto apresenta vários níveis de dificuldade e uma tela de pontuação para desafiar os reflexos. A posição da cobra é atualizada dinamicamente enquanto verifica constantemente por colisões, oferecendo uma experiência de jogo desafiadora.",
        tags: [
            {
                name: "python",
                color: "blue-text-gradient",
            },
            {
                name: "pygame",
                color: "green-text-gradient",
            },
        ],
        image: snake,
        source_code_link: "https://github.com/HenriquedSS1912/jogo_snake",
    },
];

export { services, technologies, experiences, testimonials, projects };