import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { whatsapp } from "../assets";
import { styles } from "../styles";
import { EarthCanvas } from "./canvas";
import { SectionWrapper } from "../hoc";
import { slideIn } from "../utils/motion";

const Contact = () => {
    const formRef = useRef();
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (form.email.trim().length !== 0 && form.message.trim().length !== 0 && form.name.trim().length !== 0) {
            emailjs
                .send(
                    // service-key
                    // template
                    {
                        from_name: form.name,
                        to_name: "Henrique da Silva",
                        from_email: form.email,
                        to_email: "contatojovemdev@gmail.com",
                        message: form.message,
                    },
                    // public-key
                )
                .then(
                    () => {
                        setLoading(false);
                        alert("Obrigado. Entrarei em contato com você o mais rápido possível.");
                        setForm({ name: "", email: "", message: "" });
                    },
                    (error) => {
                        setLoading(false);
                        console.error(error);
                        alert("Ah, algo deu errado :/. Por favor, tente novamente.");
                    }
                );
        }
        else {
            setLoading(false);
            alert("oops, please fill in all fields to send!");
        }
    };

    return (
        <div className={`xl:mt-12 flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}>
            <motion.div variants={slideIn("left", "tween", 0.2, 0)} className='flex-[0.75] bg-black-100 p-8 rounded-2xl'>
                <p className={styles.sectionSubText}>Fale comigo</p>
                <h3 className={styles.sectionHeadText}>Contato</h3>

                <form ref={formRef} onSubmit={handleSubmit} className='mt-10 flex flex-col gap-8'>
                    <label className='flex flex-col'>
                        <span className='text-white font-medium mb-4'>Nome</span>
                        <input
                            type='text'
                            name='name'
                            value={form.name}
                            onChange={handleChange}
                            placeholder="Digite o seu nome"
                            className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
                        />
                    </label>
                    <label className='flex flex-col'>
                        <span className='text-white font-medium mb-4'>E-mail</span>
                        <input
                            type='email'
                            name='email'
                            value={form.email}
                            onChange={handleChange}
                            placeholder="Digite o seu e-mail"
                            className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
                        />
                    </label>
                    <label className='flex flex-col'>
                        <span className='text-white font-medium mb-4'>Mensagem</span>
                        <textarea
                            rows={6}
                            name='message'
                            value={form.message}
                            onChange={handleChange}
                            placeholder='O que você quer dizer?'
                            className='bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium'
                        />
                    </label>

                    <div className="flex justify-between">
                        <a href="https://wa.me/+5519983367966" target="_blank" rel="noreferrer" className="btn-wpp">
                            <img src={whatsapp} alt="icone do WhatsApp" width={60} />
                        </a>
                        <button
                            type='submit'
                            className='bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary'>
                            {loading ? "Enviando..." : "Enviar"}
                        </button>
                    </div>
                </form>
            </motion.div>

            <motion.div variants={slideIn("right", "tween", 0.2, 0)} className='xl:flex-1 xl:h-auto md:h-[550px] h-[350px]'>
                <EarthCanvas />
            </motion.div>
        </div>
    );
};

export default SectionWrapper(Contact, "contact");
